@font-face {
  font-family: 'digitechcustom';
  src:  url('fonts/digitechcustom.eot?mrtqjg');
  src:  url('fonts/digitechcustom.eot?mrtqjg#iefix') format('embedded-opentype'),
    url('fonts/digitechcustom.ttf?mrtqjg') format('truetype'),
    url('fonts/digitechcustom.woff?mrtqjg') format('woff'),
    url('fonts/digitechcustom.svg?mrtqjg#digitechcustom') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.dic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'digitechcustom' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.dic.dic-bars-5:before {
  content: "\e900";
}
i.dic.dic-bars-4:before {
  content: "\e901";
}
i.dic.dic-bars-3:before {
  content: "\e902";
}
i.dic.dic-cog:before {
  content: "\e903";
}
i.dic.dic-sidebar:before {
  content: "\e904";
}
